import Odoo from 'odoo-xmlrpc';
import { Desencrypt, Encrypt } from '../utilities/cipher';
import { OdooConfig } from '../utilities/odoo-config';
export const AuthService = async (data, callback) => {
    const config = await OdooConfig();
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.username,
        password: data.password
    });
    client.connect(async(err, response) => {
        if (err) {
            callback(response, false)
        } else {
            Encrypt(client)
            setData(client, callback, data)
        }
    })
}

export const setData = async (client, callback, data) => {
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } 
        localStorage.setItem('uid', response)
            callback(response, true,); 
        
    })
}

export const GetList = async (data, callback) => {
    const args = [[0, data]];
    let finish = false;
    const client =  await Desencrypt();
    const config = await OdooConfig();
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: client.username,
        password: client.password
    });
    odoo.connect((err, response) => {
        if (err) {
            console.log("hubo error de conexion")
            callback(response, false)
        }
        const params = {
            model: "crmbackend.webservice",
            method: "listarleads",
        } 
        odoo.execute_kw(params.model, params.method, args, async (err, response) => {
            finish = true
            if (err) {
                console.log("Error",err)
                callback(err, false);
                return false;
            }
            callback(response, true);
        });
    })
}
export const EditLead = async (data, callback) => {
    const args = [[0, data]];
    let finish = false;
    const client =  await Desencrypt();
    const config = await OdooConfig();
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: client.username,
        password: client.password
    });
    odoo.connect((err, response) => {
        if (err) {
            console.log("hubo error de conexion")
            callback(response, false)
        }
        const params = {
            model: "crmbackend.webservice",
            method: "modificarlead",
        } 
        odoo.execute_kw(params.model, params.method, args, async (err, response) => {
            finish = true
            if (err) {
                console.log("Error",err)
                callback(err, false);
                return false;
            }
            callback(response, true);
        });
    })
}
export const ReturnData = async (data, callback) => {
    const args = [[0]];
    let finish = false;
    const client =  await Desencrypt();
    const config = await OdooConfig();
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: client.username,
        password: client.password
    });
    odoo.connect((err, response) => {
        if (err) {
            console.log("hubo error de conexion")
            callback(response, false)
        }
        const params = {
            model: "crmbackend.webservice",
            method: "retornardatos",
        } 
        odoo.execute_kw(params.model, params.method, args, async (err, response) => {
            finish = true
            if (err) {
                console.log("Error",err)
                callback(err, false);
                return false;
            }
            callback(response, true);
        });
    })
}






