import React, { useEffect } from "react";
import imagen from '../../assets/images/dpi.jpg'
import { Modal, Button } from "react-bootstrap";
import ReactStars from "react-stars";
import { useToasts } from "react-toast-notifications";
import { ClockLoader } from "react-spinners";
import { saveAs } from "file-saver";
import './styles.css'
import { EditLead, ReturnData } from "../../services/services";
import { useState } from "react";
export const ModalDetails =(props)=>{
    const {addToast} = useToasts()
    let [image,setImage] = useState('')
    let [toSend,setToSend] = useState(false)
    let [loading,setLoading] = useState(false)
    const {handleClose, item} = props
    let [imageload,setImageLoad]= useState(false)
    let [project, setProject] = useState("")
    let [stage, setStage] = useState("")
    const callback =(response,flag)=>{

        if(flag){
            response.project.forEach(element => {
                if(parseInt(element.id) === item.proyect_id){
                    setProject(element.name)
                }
                
            });
            response.crm_stage.forEach((element)=>{
                if(parseInt(element.id) === item.stage_id){
                    setStage(element.name)
                }

            })

        }
    }
    const downloadImage = () =>{
        saveAs(`data:image/jpeg;base64,${item.evidencia}`,"descarga.jpg")
    }
    const loadInfo = () =>{
        let data ={}
        ReturnData("",callback)
    }
    useEffect(()=>{
        loadInfo()
    })

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const convertirABase64 = async (event) => {
        const file = event.target.files[0];
        let base64 = await convertBase64(file);
        let newBase;
        
        newBase = base64.replace('data:image/jpeg;base64,', '');
        setImage(newBase)
        setToSend(!toSend)
        setImageLoad(!imageload)
    }

    const sendInfo = () =>{
        setLoading(true)
        let uid = localStorage.getItem("uid")
        
        let data= {
             user_id:parseInt(uid),
            id:item.id,
            stage_id:item.stage_id,
            evidencia_foto_ids:  [
                [
                    0, 0, {
                        foto: image 
                    }
                ]
            ], 
        }
        console.log("datos que se están yendo",data)
        EditLead(data,(response,flag)=>{
            setLoading(false)
            if(flag){
                let messagge = '¡Foto cambiada correctamente! ';
                addToast(messagge, { appearance: 'success' })
                handleClose()
            }
        })


    }
   

return(
    <>
        <Modal.Header closeButton>
            <Modal.Title>{item.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div style={{width:'30%'}}>
                    <span style={{fontWeight:'bold'}}>Descripción: </span> 
                    <label>{item.description}</label>
                </div>
                <div style={{width:'40%'}}>
                    <span style={{fontWeight:'bold'}}>Teléfono: </span> 
                    <label style={{marginLeft:'10px'}}>{item.mobile}</label>
                </div>
                <div style={{width:'30%'}}>
                    <span style={{fontWeight:'bold'}}>Cliente: </span> 
                    <label style={{marginLeft:'10px'}}>{item.partner_id}</label>
                </div>
            </div>
            <div className="row" style={{marginTop:30}}>
                <div style={{width:'30%'}}>
                    <span style={{fontWeight:'bold'}}>Proyecto: </span> 
                    <label>{project}</label>
                </div>
                <div style={{width:'40%'}}>
                    <span style={{fontWeight:'bold'}}>Etapa: </span> 
                    <label style={{marginLeft:'10px'}}>{stage}</label>
                </div>
                <div style={{width:'30%'}}>
                    <span style={{fontWeight:'bold'}}>Prioridad: </span> 
                    {/* <label style={{marginLeft:'10px'}}>{item.priority}</label> */}
                    <ReactStars edit={false} size={25} count={item.priority ? item.priority : 0} value={item.priority ? item.priority : 0}/>
                </div>
            </div>
            <div className="row" style={{marginTop:30}}>
                 {!imageload &&<img src={item.evidencia ? `data:image/jpeg;base64,${item.evidencia}` : imagen } height={500} width={500} alt="evidencia"/>} 
                 {imageload &&<img src={item.evidencia ? `data:image/jpeg;base64,${image}` : imagen } height={500} width={500} alt="evidencia2"/>} 
            </div>
            
        </Modal.Body>
        <Modal.Footer>
        <ClockLoader size={310} loading={loading} style={{position:'absolute', marginTop:0}}/>
            <Button variant="secondary" onClick={downloadImage} >
                Descargar
            </Button>
           {!toSend &&<div class="custom-input-file col-md-6 col-sm-6 col-xs-6">
                <input type="file" onChange={(e)=>{convertirABase64(e)}} id="fichero-tarifas" class="input-file" value=""/>
                Cambiar Foto
            </div>}
            {toSend &&<Button variant="success" onClick={sendInfo} >
                Enviar
            </Button>}
        </Modal.Footer>

</>
);

}