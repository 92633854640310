import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../App.css"
import { useToasts } from "react-toast-notifications";
import { ClockLoader } from "react-spinners";
import ReactLoading from 'react-loading'
import { GetList } from "../../services/services";
import { Cards } from "./components/cards";
export const Leads =()=>{
    const { addToast } = useToasts();
    let [loading,setLoading] = useState(false)
    let [leads, setLeads] = useState([])
    let [page, setPage] = useState(1)
    

    const reloadLeads = () =>{
        setPage(page+1)
        let uid = localStorage.getItem("uid")
        let data = {pagina:page,user_id:parseInt(uid)}
        loadList(data,callback)

    }
    const callback =(response,flag) =>{
        setLoading(false)
        if(flag){
            let tasks = []
            response.leads_ids.forEach(element => {
                let position = element.evidencia_foto_ids.length
                //console.log("posicion",position)
                
                tasks.push({evidencia:element.evidencia_foto_ids[position-1], description: element.description,date_deadline: element.date_deadline,label:element.name, id: parseInt(element.id),stage_id: element.stage_id, proyect_id: element.proyect_id, priority: element.priority, planned_revenue: element.planned_revenue, partner_id: element.partner_id, mobile: element.mobile})
            });
            tasks.sort((a,b) =>{
                return  b.id-a.id
              })
            setLeads(tasks)
        }else{
            alert("estamos teniendo problemas con el servidor. Inténtalo más tarde")
        }
    }
    const loadList = () =>{
        setLoading(true)
        let uid = localStorage.getItem("uid")
        let data = {pagina:page,user_id:parseInt(uid)}
         GetList(data,callback)
    }

    const closeSession = () =>{
        let messagge = 'Cerrando Sesión ';
                addToast(messagge, { appearance: 'warning' })
        localStorage.clear();
        setTimeout(() => {
            window.location.href = "/";
        }, 950);
    }
    

    useEffect(()=>{
        loadList()
    },[])

    return(
        <>
            <ClockLoader size={1010} loading={loading} style={{position:'absolute', marginTop:0}}/>
            <div className=" App">
            
                <div className="container d-flex justify-content-center h-0 align-items-center">
                    <Cards loadLeads={loadList} leads={leads}/>
                </div>
                <div className="container d-flex justify-content-center h-0 align-items-center" style={{marginTop:20}} >
                    <Button className="btn btn-success" onClick={reloadLeads}>Descargar más oportunidades</Button>
                </div>
                <div className="container d-flex justify-content-center h-0 align-items-center" style={{marginTop:20}} >
                    <Button className="btn btn-danger" onClick={closeSession}>Cerrar Sesión</Button>
                </div>
            
            </div>
        </>
        
        
    );

}