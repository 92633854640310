import React, {useState} from "react";
import dpi from "../../../assets/images/dpi.jpg"
import { Modal, Button } from "react-bootstrap";
import { ModalDetails } from "../../../shared/modals/modalDetails";

export const Card =(props)=>{
    const {lead, loadLeads} = props
    const [show, setShow] = useState(false);
    const [item, setItem] = useState([]);
    const handleClose = () => {setShow(false); loadLeads()};
    const handleShow = () => setShow(true);

    return(
        <>
            <div className="card card-content text-center bg-dark">
                <img src={dpi} style={{width:'20%', height:'20%'}} /> 
                <div className="card-body text-light">
                    <h4 className="card-title">{lead.label}</h4>
                    <label className="card-text" >
                        Valor: ${lead.planned_revenue}
                    </label>
                    <label className="card-text" style={{marginLeft:'10px'}}>
                        Fecha prevista: {lead.date_deadline}
                    </label>
                    <a className="btn btn-outline-secondary rounded-0" onClick={()=>{handleShow();setItem(lead)}}>
                        Ver Detalle
                    </a>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <ModalDetails item={item} handleClose={handleClose}/>
            </Modal> 
       </>
    );

}