import React from "react";
import { Card } from "./card";
import './cards.css'
export const Cards =(props)=>{
    
    const {leads, loadLeads} = props
    

    return(
        <div className="container">
            <div className="row">
                
                    {leads.map(lead=>(
                        <div className="col-md-4 card-content" key={lead.id}>
                            <Card lead={lead} loadLeads={loadLeads}/>
                        </div>

                    ))}
                
            </div>
        </div>
    );

}