import React, { useEffect, useState } from 'react';
import {  Navigate, createBrowserRouter } from 'react-router-dom';
import { Desencrypt } from './utilities/cipher';
import Login from './user/login';
import { Leads } from './user/list/list';
import { ProtectedRoute } from './protectedRoute';
const user = Desencrypt()

export const RoutesDPI =  createBrowserRouter ([
    {
        path:"*",
        element: <Login/>
    },
    {
        path:"/list",
        element:
            <ProtectedRoute user={user}>
                <Leads/>
            </ProtectedRoute> 
    },

])
