import React, { useState } from 'react'
import { Grid, Container, Paper, Avatar, Typography, TextField, Button, CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import fondo from '../assets/images/fondo.png'
import {useFormik} from 'formik'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import { AuthService } from '../services/services'


const useStyles = makeStyles(theme => ({
	root: {
		backgroundImage: `url(${fondo})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height: '100vh'
	},
	container: {
		opacity: '0.8',
		height: '60%',
		marginTop: theme.spacing(10),
		[theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
			marginTop: 0,
			width: '100%',
			height: '100%'
		}
	},
	div: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	button: {
		margin: theme.spacing(3, 0, 2)
	}
}))

const Login = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();

	
	const classes = useStyles()
	const callback =(flag,response)=>{
		if (flag) {
            let messagge = '¡Bienvenido a DPI ';
                addToast(messagge, { appearance: 'success' })
                setTimeout(() => {
                    window.location.href = "/list";
                }, 750);
            
        } else {
            let messagge = 'Usuario y/o contraseña incorrecto ';
                addToast(messagge, { appearance: 'error' })
               
        }
	}
	const {values,isSubmitting, setFieldValue, handleSubmit, errors, resetForm} = useFormik({
		initialValues:{
            username:'',
            password:'',
        },
        onSubmit: values=>{
            resetForm();  
            const data = {
                username:values.username,
                password:values.password
            }
           AuthService(data,callback)
        },
        validate: values =>{
            const errors={}
            if(values.username.length === 0) errors.username="Debe ingresar un correo"
            if(values.password.length === 0) errors.password="Debe ingresar una contraseña"
            return errors
        }   
    })

	return (
		<Grid container component='main' className={classes.root}>
			<CssBaseline />
			<Container component={Paper} elevation={5} maxWidth='xs' className={classes.container}>
				<div className={classes.div}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>Iniciar Sesión</Typography>
					<form className={classes.form}>
						<TextField
							fullWidth
							autoFocus
							color='primary'
							margin='normal'
							variant='outlined'
							label='username'
							name='username'
							value={values.username}
							onChange={(e)=>{setFieldValue('username',e.target.value)} }
						/>
						<TextField
							fullWidth
							type='password'
							color='primary'
							margin='normal'
							variant='outlined'
							label='Password'
							name='password'
							value={values.password}
							onChange={(e)=>{setFieldValue('password',e.target.value)} }
						/>
						<Button
							fullWidth
							variant='contained'
							color='secondary'
							className={classes.button}
							onClick={handleSubmit}
						>
							Iniciar Sesión
						</Button>
					</form>
				</div>
			</Container>
		</Grid>
	)
}

export default Login
