import React from 'react';
import ReactDOM from 'react-dom/client';
import {RoutesDPI} from './routes';
import {RouterProvider} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastProvider } from 'react-toast-notifications';
ReactDOM.createRoot(document.getElementById('root')).render(
	
	<React.StrictMode>
		<ToastProvider>
			<RouterProvider router={RoutesDPI}/>
		</ToastProvider>
	</React.StrictMode>
);




