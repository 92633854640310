import React from 'react';
import {  Navigate } from 'react-router-dom';
import { Desencrypt } from './utilities/cipher';

const data = Desencrypt

export const ProtectedRoute = ({children,user}) =>{
    if(!user){
        return <Navigate to="*"/>
    }

    return children
}
